import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Enable Vue extensions (make available as Vue.*)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
//Vue.component('BIconPersonFill', BIconPersonFill)
//Vue.component('BIconCart3', BIconCart3)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
