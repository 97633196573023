<template>
    <div class="Dashboard">
        <h1>Dashboard</h1>
        <b-card-group>
          <b-card v-for="reading in readings" :key="reading.key">
            <img v-if="reading.image" :src="reading.image + '?size=xs'">
              <b-card-text>
            <h2>{{reading.label}}</h2>
            <p>{{reading.value}}
                <span v-if="reading.value_2"><br/><div style="font-size: 0.8em">{{reading.value_2}}</div></span>
            </p>
                  </b-card-text>
          </b-card>
        </b-card-group>
    </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'Dashboard',
    data() {
      return {
        readings: []
      }
    },
    methods: {
      load_readings() {
        axios.get("/api/readings/latest/all?format=json")
          .then(response => {this.readings = response.data.readings
          }).catch((error) => {
            console.error(error)
            this.$root.$emit('show_backend_error', error)
          })
      },
      _load_readings() {
        this.readings = [
            {id: '1', label: 'Stue', value: '20.0°C', value_2: '58%'},
            {id: '2', label: 'Altan', value: '20.0°C', value_2: '997hPa'},
            {id: '3', label: 'Sauna', value: '20.0°C'},
            {id: '4', label: 'Havesauna', value: '20.0°C'},
            {id: '5', label: 'Brønd', value: '20.0°C'},
        ]
      },
    },
    mounted: function() {
      console.log("Dashboard mounted")
      this.load_readings()
      this.interval = setInterval(this.load_readings, 60000)
    },
    beforeDestroy() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = undefined
      }
    },

  };
</script>


<style>
.Dashboard {
  color: white;
}

.Dashboard .card {
  color: #1c1e50;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 20px;
  min-width: 15rem;
}
.Dashboard article img {
  margin: -20px -20px 20px -20px;
  width: calc(100% + 40px)
}
</style>
