<template>
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="container">
        <a class="navbar-brand" href="/">SHM</a>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0 right">
            <li class="nav-item" style="padding-right: 20px">
              <b-button-group size="sm" id="top-btn-group">
                <b-button variant="primary" id="btn-dashboard">Dashboard</b-button>
                <b-button variant="primary" id="btn-advisories">History</b-button>
              </b-button-group>
            </li>
          </ul>
        </div>
      </div>
    </nav>
</template>


<script>
  export default {
    name: 'TopNavbar',
    data() {
      return {
      }
    },

    methods: {
    },

    mounted: function() {
    },
  }
</script>


<style>
#CartIcon {
  color: white;
  font-size: 1.5rem;
  margin: 14px 10px 0 0;
  cursor: pointer;
}
#CartBadge {
  position: relative;
  top: -19px;
  left: -10px;
  background-color: darkslategray;
  font-size: 0.75rem;
}
</style>