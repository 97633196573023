<template>

  <div id="app" style="min-height:100vh">
    <TopNavbar/>

    <!-- Main view -->
    <div class="container">
        <b-alert :show="show_alert" dismissible>Something went wrong<br>{{error_msg}}</b-alert>
        <component :is="main_component"></component>
    </div>
  </div>
</template>

<script>
import TopNavbar from './components/TopNavbar.vue'
import Dashboard from './components/Dashboard.vue'

export default {
  name: 'App',
  components: {
    TopNavbar,
    Dashboard,
  },
  data() {
    return {
      main_component: "Dashboard",
      show_alert: false,
      error_msg: "",
    }
  },
  methods: {
  },
  mounted: function() {
  },
}
</script>

<style>
body {
  background-color: lightgray !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1c1e50;
  margin-top: 0;
}
#top-btn-group {
  margin-right: 20px;
  margin-top: 10px;
}
.btn-primary, .btn-primary:hover, .btn-secondary, .btn-secondary:hover {
  background-color: #2397C4 !important;
  border-color: #2397C4;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
}
</style>
